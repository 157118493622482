<template>
	<section class="permission">
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="id" label="id" width="50">
			</el-table-column>	
			<el-table-column prop="userName" label="用户名" width="150">
			</el-table-column>
			<el-table-column prop="storeName" label="店铺名称" width="150">
			</el-table-column>
			<el-table-column prop="domainName" label="订单号" width="200">
			</el-table-column>
			<el-table-column prop="time" label="购买内容">
			</el-table-column>
			<el-table-column prop="address" label="金额" width="150">
			</el-table-column>
			<el-table-column prop="phone" label="有效期" width="180">
			</el-table-column>
		</el-table>
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
	import { getAdminList, stateAdmin} from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				listData: [
					{
						id: 1,
						userName: '王大崔',
						storeName: '白富美的店',
						domainName: 'www.wangdacui.com',
						time: '2019-11-24 11:29:10',
						address: '梦醒前一刻',
						userName: '王大崔',
						phone:'18888888888',
						state: 1
					}
				],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: [],//列表选中列
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					page: this.page,
					keyword: this.filters.keyword,
					pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				getAdminList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},			
		},
		mounted() {
			//获取列表数据
			//this.getList();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>